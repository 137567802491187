<template>
  <v-card @click="goToLavorazioneCarroConvoglio()" :disabled="carroConvoglio.visitaCarro.parcoExternal" :class="`wagon ${highlighted===true?'highlighted':''}`">

    <v-app-bar flat :color="getClassCarroConvoglio(carroConvoglio)">
      <v-toolbar-title class="text-h5 font-weight-black ">{{ carroConvoglio.visitaCarro.targa }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-chip class="text-caption header ma-1">
        <b>{{ this.labelConvenzionale }}</b>
      </v-chip>
      <v-chip class="text-body-1 header ma-1">
        <b>{{ carroConvoglio.cancellato ? "" : carroConvoglio.posizione }}</b>
      </v-chip>
    </v-app-bar>

    <v-card-text class="grey lighten-2">
      <v-container class="pa-0 ma-0" >
        <v-row align="center" justify="center" class="pa-0 ma-0">
          <v-col class="pa-0 ma-0" v-if="carroConvoglio.visitaCarro.parcoExternal">
            <v-sheet elevation="0" rounded="lg" class="text-body-1 font-weight-regular pa-3 ma-3">
              gestione esterna
            </v-sheet>
          </v-col>
          <v-col class="pa-0 ma-0" v-else-if="listUti && listUti.length > 0">
            <v-sheet elevation="1" rounded="lg" :class="`text-body-1 font-weight-regular pa-3 ma-3 ${getClass(utiCarro)}`" v-for="utiCarro in listUti" :key="utiCarro.id">
              <v-container class="pa-0 ma-0">
                <v-row class="pa-0 ma-0" justify="center">
                  <v-col cols="12" @click.stop="handleCaricoScarico(utiCarro)" class="font-weight-bold pa-0 ma-0" :disabled="utiCarro.visitaUti.gateOut!='' " >{{ displayUtiCarro(utiCarro) }}</v-col>
                </v-row>
                <v-row class="pa-0 ma-0" justify="center">
                  <v-col cols="12" @click.stop="handleCaricoScarico(utiCarro)" class="font-weight-bold pa-0 ma-0" :disabled="utiCarro.visitaUti.gateOut!=''" style="font-size:12px;">
                    {{ utiCarro.operator }}
                  </v-col>
                </v-row>
                <v-row class="pa-0 ma-0" justify="center" v-if="utiCarro.areaScarico">
                  <v-col cols="12" class="pa-0 ma-0 d-flex justify-end">
                    <v-icon small color="success">mdi-arrow-right-bold</v-icon>
                    {{ utiCarro.areaScarico }}
                  </v-col>
                </v-row>
              </v-container>
            </v-sheet>
          </v-col>
          <v-col class="pa-0 ma-0" v-else>
            <v-sheet elevation="1" rounded="lg" class="text-body-1 font-weight-regular pa-3 ma-3">
              NO UTI
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-dialog v-model="dialogAreaStoccaggio" persistent width="600">
      <v-card>
        <v-card-title>{{ this.titoloCaricoScarico }}</v-card-title>
        <v-card-text>
          <v-text-field v-model="areaStoccaggio" label="Area Stoccaggio" style="max-width:300px;" />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="executeCaricoScarico(currentUtiCarro, areaStoccaggio)" small>{{ this.bottoneCaricoScarico }}</v-btn>
          <v-btn @click="dialogAreaStoccaggio = false" small>Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import railsMixins from "../rails/mixins/railsMixins";

export default {
  name: "WagonExecutionViewPanel",
  components: {},
  mixins: [railsMixins],
  data() {
    return {
      utiList: [],
      dialogAreaStoccaggio: false,
      areaStoccaggio: "",
      currentUtiCarro: {},
      bottoneCaricoScarico: "",
      titoloCaricoScarico: "",
    };
  },
  props: {
    carroConvoglio: {
      type: Object,
      required: true,
    },
    highlighted: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  mounted() {
    this.utiList = this.listUti;
  },
  computed: {
    listUti() {
      var listUti = [];
      if (this.carroConvoglio && this.carroConvoglio.listUti && this.carroConvoglio.listUti.length > 0) {
        listUti = this.carroConvoglio.listUti.filter(utiWagon => utiWagon.tipoMovimento.toLowerCase() === "export");
      }
      return listUti;
    },
    labelConvenzionale() {
      if (this.carroConvoglio && this.carroConvoglio.visitaCarro && this.carroConvoglio.visitaCarro.carro && this.carroConvoglio.visitaCarro.carro.tipoCarro) {
        if (this.carroConvoglio.visitaCarro.carro.tipoCarro.convenzionale) {
          return "CONV.";
        } else {
          return "NON CONV.";
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    enableScaricaAnnullaScaricoUtiCarro(utiCarro) {
      console.log("(utiCarro.stato: ", utiCarro.stato);
      if (utiCarro.stato === "CANCELLATO") {
        return "";
      } else {
        this.scaricaAnnullaScaricoUtiCarro(utiCarro);
      }
    },

    goToLavorazioneCarroConvoglio() {
      this.setWagonOnFocusRef();
      this.$router.push({ path: "/callTreno/LavorazioneCarroConvoglio/" + this.carroConvoglio.id });
      return;
    },
    handleCaricoScarico(utiCarro) {
      if (utiCarro.stato === "CANCELLATO") {
        return "";
      } else {
        var daScaricare = utiCarro.stato === "CARICATO" ? true : false;
        if (daScaricare) {
          this.openDialogCaricoScarico(utiCarro);
        } else {
          this.scaricaAnnullaScaricoUtiCarro(utiCarro, null).then(value => {
            if (value) {
              this.showSuccess("Operazione effettuata correttamente");
              this.utiList.forEach(element => {
                if (element.id === value.id) {
                  element.areaScarico = value.areaScarico;
                }
              });
            }
          });
        }
      }
    },
    openDialogCaricoScarico(utiCarro) {
      this.currentUtiCarro = utiCarro;
      this.areaStoccaggio = utiCarro.areaScarico;
      this.dialogAreaStoccaggio = true;
      const statoAttuale = utiCarro.stato;
      this.bottoneCaricoScarico = statoAttuale === "SCARICATO" ? "CARICA" : "SCARICA";
      this.titoloCaricoScarico = statoAttuale === "SCARICATO" ? "CARICA" : "SCARICA";
    },
    executeCaricoScarico(utiCarro, areaStoccaggio) {
      this.scaricaAnnullaScaricoUtiCarro(utiCarro, areaStoccaggio).then(value => {
        if (value) {
          this.showSuccess("Operazione effettuata correttamente");
          this.utiList.forEach(element => {
            if (element.id === value.id) {
              element.areaScarico = value.areaScarico;
            }
          });
        }
        this.dialogAreaStoccaggio = false;
      });
    },
    getClass(utiCarro) {
      if(utiCarro.visitaUti.gateOut != "") return "white";
      if (utiCarro.remainOnBoardScarico && utiCarro.remainOnBoardScarico === true) {
        return "uti-rob";
      } else if (utiCarro.rifiutato === true) {
        return "red lighten-2";
        // } else if (utiCarro.stato==="SCARICATO") {
        //   return "teal lighten-2";
      } else if (utiCarro.stato === "CANCELLATO") {
        return "grey lighten-1";
      } else {
        return this.getUtiStateColorText(utiCarro);
      }
    },
    getClassCarroConvoglio(carroConvoglio) {
      if (carroConvoglio.cancellato) {
        return "grey lighten-1";
      } else {
        return "warning";
      }
    },
    setWagonOnFocusRef() {
      const data = {key:this.$router.history.current.path , wagonOnFocusRef:'wagon_'+this.carroConvoglio.id+'_'+this.carroConvoglio.posizione};
      console.log("setWagonOnFocusRef --- ", data);
      this.$store.commit('configuration/setWagonOnFocusRef', data);
    },
  },
};
</script>

<style scoped>
.wagon {
  vertical-align: top;
}
.highlighted {
  border: 5px solid#effe65 !important;
}
.uti-rob {
  background-image: repeating-linear-gradient(-45deg, #ffffff, #ffffff 5px, rgb(196, 196, 192) 5px, rgb(196, 196, 192) 10px) !important;
}
</style>
