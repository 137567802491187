<template>
  <div>
    <v-expansion-panels v-model="editPanel" multiple>
      <v-expansion-panel>
        <v-expansion-panel-header class="text-body-1 text-uppercase font-weight-regular py-2 panelheader">
          INFO CONVOGLIO
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ConvoyInfoPanelFull :convoglio="convoglio" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card class="my-2">
      <v-card-text>
        <v-container fill-height fluid>
          <v-row align="center" justify="center">
            <v-col>
              <v-btn
                :disabled="disableBtnScaricaCarroConvoglio(convoglio)"
                block
                x-large
                :color="colorBtnScaricaCarroConvoglio"
                @click="doConfirm(labelConfermaBtnScaricaCarroConvoglio, 'scaricaConvoglio', convoglio)"
              >
                {{ labelBtnScaricaCarroConvoglio }}
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                v-if="convoglio && convoglio.viaggioIn && convoglio.viaggioIn.statoOperativo !== 'FINELAVORAZIONE'"
                block
                x-large
                color="primary"
                @click="fineScaricoAnnullaFineScarico(convoglio)"
              >
                Fine Scarico
              </v-btn>
              <v-btn v-else block x-large color="blue-grey" @click="fineScaricoAnnullaFineScarico(convoglio)">
                Fine Scarico
              </v-btn>
            </v-col>
            <v-col class="text-h4 text-center font-weight-regular">
              <v-row> Carri scaricati: {{ dischargedWagons }} / {{ toDischargeWagons }} / {{ totalWagons }} </v-row>
              <v-row>
                Uti scaricate: {{ dischargedUti }} / {{ toDischargedUti }} /
                {{ totalUti }}
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card>
      <v-card-title class="text-body-1 text-uppercase font-weight-regular py-2 my-2 panelheader">LAVORAZIONE CARRI CONVOGLIO</v-card-title>
      <v-card-text>
        <v-container class="py-0 my-0">
          <v-row class="pb-2 my-2">
            <v-col cols="3">
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Cerca" single-line hide-details clearable class="text-h5 font-weight-bold" />
            </v-col>
          </v-row>
        </v-container>
        <ConvoyExecutionViewPanel :listCarri="convoglio.listCarri" :search="search" />
      </v-card-text>
    </v-card>

    <v-dialog v-model="confirmDialogVisible" persistent max-width="290" header="Conferma">
      <v-card>
        <v-card-title>Scarica carri convoglio</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              {{ confirmMessage }}
            </v-col>
          </v-row>
          <v-row v-if="!carroConvoglioScaricatoBoolean">
            <v-col>
              <v-text-field v-model="area" label="Inserisci l'area di scarico" single-line clearable />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="onConfirm" small>Si</v-btn>
          <v-btn @click="confirmDialogVisible = false" small>No</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ConvoyInfoPanelFull from "../../components/misc/ConvoyInfoPanelFull";
import ConvoyExecutionViewPanel from "../../components/misc/ConvoyExecutionViewPanel";
import railsMixins from "../../components/rails/mixins/railsMixins";

export default {
  name: "ConvoyExecution",
  components: {
    ConvoyInfoPanelFull,
    ConvoyExecutionViewPanel,
  },
  mixins: [railsMixins],
  data() {
    return {
      editPanel: [0],
      search: null,
      confirmMethod: null,
      confirmData: null,
      confirmMessage: null,
      confirmDialogVisible: null,
      area: null
    };
  },
  props: {
    convoglio: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // TOOD Rimuovere tutti questi computed  e usare campi in DTO convoglio (vedere ConvoglioDtoBuilder.java#setComputedFields)
    dischargedWagons() {
      var carriScaricati = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        this.convoglio.listCarri.forEach(carroConvoglio => {
          if (this.carroConvoglioScaricato(carroConvoglio)) {
            carriScaricati++;
          }
        });
      }
      return carriScaricati;
    },
    toDischargeWagons() {
      var carriDaScaricare = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        this.convoglio.listCarri.forEach(carroConvoglio => {
          if (carroConvoglio.listUti && carroConvoglio.listUti.length > 0) {
            var rob = 0;
            carroConvoglio.listUti.forEach(utiCarro => {
              if (utiCarro.remainOnBoardScarico === true) {
                rob++;
              }
            });
            if (rob !== carroConvoglio.listUti.length) {
              carriDaScaricare++;
            }
          }
        });
      }
      return carriDaScaricare;
    },
    totalWagons() {
      var totalWagon = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        totalWagon = this.convoglio.listCarri.length;
      }
      return totalWagon;
    },
    dischargedUti() {
      var utiScaricate = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        this.convoglio.listCarri.forEach(carroConvoglio => {
          utiScaricate = utiScaricate + this.utiScaricate(carroConvoglio);
        });
      }
      return utiScaricate;
    },
    toDischargedUti() {
      var utiDaScaricare = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        this.convoglio.listCarri.forEach(carroConvoglio => {
          utiDaScaricare = utiDaScaricare + this.utiDaScaricare(carroConvoglio);
        });
      }
      return utiDaScaricare;
    },
    totalUti() {
      var totalUti = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        this.convoglio.listCarri.forEach(carroConvoglio => {
          totalUti = totalUti + carroConvoglio.listUti.length;
        });
      }
      return totalUti;
    },
    carroConvoglioScaricatoBoolean() {
      var numScaricati = 0;
      var numDaScaricare = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        this.convoglio.listCarri.forEach(carroConvoglio => {
          carroConvoglio.listUti.forEach(utiCarro => {
            //console.log("GateOut", utiCarro.visitaUti.gateOut, utiCarro.remainOnBoardScarico === false, utiCarro.visitaUti.gateOut === "" , numDaScaricare);
            if (utiCarro.remainOnBoardScarico === false && utiCarro.visitaUti.gateOut === "") {
              numDaScaricare++;
              if (utiCarro.stato === "SCARICATO") {
                numScaricati++;
              }
            }
          });
        });
      }
      console.log("numDaScaricare", numDaScaricare, numScaricati);
      if (numDaScaricare === numScaricati) {
        return true;
      } else {
        return false;
      }
    },
    colorBtnScaricaCarroConvoglio() {
      if (this.carroConvoglioScaricatoBoolean === true) {
        return this.getUtiStateColorTextByTipoStato("export", "scaricato");
      } else {
        return this.getUtiStateColorTextByTipoStato("export", "caricato");
      }
    },
    labelConfermaBtnScaricaCarroConvoglio() {
      if (this.carroConvoglioScaricatoBoolean === true) {
        return "Confermi l'annulla scarico dei carri convoglio ?";
      } else {
        return "Confermi lo scarico dei carri convoglio ?";
      }
    },
    labelBtnScaricaCarroConvoglio() {
      if (this.carroConvoglioScaricatoBoolean === true) {
        return "ANNULLA SCARICO CARRI CONVOGLIO";
      } else {
        return "SCARICA CARRI CONVOGLIO";
      }
    },
  },
  methods: {
    disableBtnScaricaCarroConvoglio(convoglio) {
      if (convoglio && convoglio.viaggioIn && (convoglio.viaggioIn.statoOperativo === "FINELAVORAZIONE" || convoglio.viaggioIn.statoOperativo === "CHIUSO") ) {
        return "disabled";
      }
    },

    async fineScaricoAnnullaFineScarico(convoglio) {
      try {
        if (convoglio && convoglio.viaggioIn) {
          if (convoglio.viaggioIn.statoOperativo !== "FINELAVORAZIONE") {
            const id = convoglio.viaggioInId;
            const state = "FINELAVORAZIONE";
            await this.$api.trainData.updateViaggioTrenoStatoOperativo(id, state);
          } else {
            const id = convoglio.viaggioInId;
            const state = "INLAVORAZIONE";
            await this.$api.trainData.updateViaggioTrenoStatoOperativo(id, state);
          }
        }
      } catch (e) {
        const message = e.detailMessage ? e.detailMessage : e;
        this.showError(message);
      } finally {
        this.$eventBus.$emit("convoyReload");
      }
    },
    async scaricaConvoglio(convoglio) {
      try {
        await this.$api.trainData.scaricaConvoglio(convoglio.id, this.area);
      } catch (e) {
        const message = e.detailMessage ? e.detailMessage : e;
        this.showError(message);
      } finally {
        this.$eventBus.$emit("convoyReload");
        this.area = null;
      }
    },

    carroConvoglioScaricato(carroConvoglio) {
      var numScaricati = 0;
      var numDaScaricare = 0;
      if (this.convoglio && this.convoglio.listCarri) {
        carroConvoglio.listUti.forEach(utiCarro => {
          if (utiCarro.remainOnBoardScarico === false && utiCarro.rifiutato === false) {
            numDaScaricare++;
            if (utiCarro.stato === "SCARICATO") {
              numScaricati++;
            }
          }
        });
        if (numDaScaricare !== 0 && numDaScaricare === numScaricati) {
          return true;
        } else {
          return false;
        }
      }
    },
    utiScaricate(carroConvoglio) {
      var numScaricati = 0;
      carroConvoglio.listUti.forEach(utiCarro => {
        if (utiCarro.stato === "SCARICATO") {
          numScaricati++;
        }
      });
      return numScaricati;
    },
    utiDaScaricare(carroConvoglio) {
      var numDaScaricare = 0;
      carroConvoglio.listUti.forEach(utiCarro => {
        if (utiCarro.remainOnBoardScarico === false && utiCarro.rifiutato === false) {
          numDaScaricare++;
        }
      });
      return numDaScaricare;
    },
    doConfirm(confirmMessage, confirmMethod, confirmData) {
      this.confirmMessage = confirmMessage;
      this.confirmData = confirmData;
      this.confirmMethod = confirmMethod;
      this.confirmDialogVisible = true;
    },
    onConfirm() {
      this.confirmDialogVisible = false;
      let method = this[this.confirmMethod];
      if (method == null) {
        console.warn("Confirm method null:", this.confirmMethod);
        return;
      }
      method(this.confirmData);
    },
  },
};
</script>

<style scoped></style>
