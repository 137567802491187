<template>
  <div class="outer">
    <v-sheet v-for="carroConvoglio in listCarriFiltered" :key="carroConvoglio.id">
      <WagonExecutionViewPanel 
        :carroConvoglio="carroConvoglio" 
        :ref="`wagon_${carroConvoglio.id}_${carroConvoglio.posizione}`"
        :highlighted="wagonOnFocusRef===`wagon_${carroConvoglio.id}_${carroConvoglio.posizione}`"
      />
    </v-sheet>
  </div>
</template>

<script>
import WagonExecutionViewPanel from "../../components/misc/WagonExecutionViewPanel";

export default {
  name: "ConvoyExecutionViewPanel",
  components: {
    WagonExecutionViewPanel,
  },
  mixins: [],
  data() {
    return {
      wagonOnFocusRef: {}
    };
  },
  mounted() {
    this.getFocus();
  },
  updated() {
    this.getFocus();
  },
  props: {
    listCarri: {
      type: Array,
      default: () => [],
    },
    search: null,
  },
  computed: {
    listCarriFiltered() {
      if (this.search) {
        var ls = [];
        this.listCarri.forEach((c) => {
          if(c.visitaCarro.targa.toLowerCase().includes(this.search.toLowerCase())){
            ls.push(c);
          }
          c.listUti.forEach((u) =>{
            if(u.visitaUti){
             if(((u.visitaUti.uti && u.visitaUti.uti.codice.toLowerCase().includes(this.search.toLowerCase())) 
             || (u.visitaUti.uti === null &&  (u.visitaUti.merce && u.visitaUti.merce.toLowerCase().includes(this.search.toLowerCase()))))){
              ls.push(c);
             }
            }
          });
        });
        var uniqueItems = Array.from(new Set(ls));
        return uniqueItems;
      }
      return this.listCarri;
    },
  },
  methods: {
    getFocus() {
      this.wagonOnFocusRef = this.$store.getters['configuration/wagonOnFocusRef'](this.$router.history.current.path);    
      if (this.wagonOnFocusRef && this.$refs[this.wagonOnFocusRef]) {     
        this.$refs[this.wagonOnFocusRef][0].$el.focus();
      }
    },
  }
};
</script>

<style scoped>
.outer {
  width: 100%;
  height: 100%;
  white-space: nowrap;
  position: relative;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
}

.outer div {
  float: none;
  height: 100%;
  margin: 0 1%;
  display: inline-block;
}
</style>